<template>
<vs-row v-if="$parent.user != null" style="padding-top: 0px;" class="store" justify="space-between">
    <input type="file" multiple ref="imageInput" style="display: none;" accept="image/*" @change="fileInputChange" />

    <vs-dialog v-model="mobileDisclamer">
        <!-- this dialog should inform the user that he should use desktop device for better experience, button is "Continue anyway" or "Back to feed"-->
        <template #header>
            <h4 style="margin-bottom: 0;">
                Attenzione
            </h4>
        </template>

        <div class="con-form">
            <vs-row justify="center">
                <p style="margin: 0;">Per una migliore esperienza ti consigliamo di utilizzare un dispositivo desktop.</p>
            </vs-row>
        </div>

        <template #footer>
            <vs-row justify="space-between">
                <vs-button transparent size="large" block style="margin:0; margin-bottom: 10px;" @click="mobileDisclamer = false">
                    Continua comunque
                </vs-button>
                <vs-button size="large" block style="margin:0;"  @click="() => {mobileDisclamer = false; $router.push({name: 'feed'});}">
                    Torna al Feed
                </vs-button>
            </vs-row>
        </template>
    </vs-dialog>

    <vs-dialog v-model="smartCompletionDialog" blur prevent-close width="450px">
        <!-- ask to user if he wants to upload photos from pc or select posts from feed-->
        <template #header>
            <h4 style="margin-bottom: 0;">
                Compilazione Smart
            </h4>
        </template>

        <div class="con-form" style="margin-top: 20px;">
            <vs-row justify="center">
                <p>La compilazione smart ti aiuta a creare il tuo prodotto in modo veloce e intuitivo.<br/><br/>Puoi scegliere di caricare le tue immagini e le pagine verranno automaticamente composte per te.</p>

                <h3 style="margin: 0; margin-top: 20px; margin-bottom: 20px;">Scegli come vuoi compilare le pagine:</h3>

            </vs-row>
        </div>



        <vs-button style="margin: 0;" size="large" block color="primary" class="animate" @click="() => {elementReference = {type: 'IMAGE'}; smartCompletionDialog = false; elementChooseDialog = true; smartMode = true;}">
            <i class='bx bxs-photo-album bx-sm'></i> Scegli dal tuo Feed
        </vs-button>
        <vs-button style="margin: 0; margin-top: 10px;" size="large" block @click="() => {elementReference = {type: 'IMAGE'}; smartCompletionDialog = false; elementChooseDialog = true; smartMode = true; $refs.imageInput.click();}">
            <i class='bx bx-upload bx-sm'></i> Scegli dal tuo PC
        </vs-button>
        <p style="width: 100%; text-align: center; margin-top: 15px; margin-bottom: 15px;">oppure</p>
        <vs-button dark border style="margin: 0;" size="large" block @click="smartCompletionDialog = false">
            Prosegui manualmente
        </vs-button>
       
    </vs-dialog>

    
    <vs-dialog v-model="confirmProductUpgradeDialog" blur prevent-close>
        <template #header>
            <h4 style="margin-bottom: 0;">
                Complimenti!
            </h4>

        </template>

        <div class="con-form">
            <vs-row justify="center">
                <p style="margin: 0;">Hai raggiunto un numero di pagine sufficienti per poter proseguire al checkout. Ora puoi scegliere se con continuare la configurazione del tuo prodotto o procedere con l'acquisto.</p>
                <p style="margin:0;">Ricorda che se decidi di procedere al checkout, non sarà più possibile tornare alla configurazione per modificare il prodotto.</p>
            </vs-row>
        </div>

        <template #footer>
            <vs-row justify="space-between">
                <vs-button style="width: 49%; margin:0;" transparent @click="() => {confirmProductUpgradeDialog = false; layoutChooseDialog = true;}">
                    Continua la configurazione
                </vs-button>
                <vs-button style="width: 49%; margin:0;" @click="checkout()">
                    Procedi al checkout
                </vs-button>
            </vs-row>

        </template>
    </vs-dialog>

    <vs-dialog v-model="layoutChooseDialog" prevent-close class="chooseLayout">
        <template #header>
            <h4 style="margin-bottom: 0;">
                Scegli un <b>modello</b>
            </h4>

        </template>

        <vs-row justify="flex-end" style="margin-bottom: 20px;">
            <vs-button class="animate" color="primary" @click="smartCompletionDialog = true; layoutChooseDialog = false;">
                <i class='bx bx-sm bxs-magic-wand'></i> 
                Compilazione Smart
            </vs-button>
        </vs-row>

        <vs-row>
            <vs-col w="4" v-for="layout in layouts" :key="'layout-' + layout.id">
                <div @click="layoutChooseDialogValue = layout.id" class="image-box-sel" :class="{'selected': layout.id == layoutChooseDialogValue}" :style="{'background-image': 'url(' + layout.previewFileUrl + ')'}"></div>
            </vs-col>
        </vs-row>

        <template #footer>
            <div class="footer-dialog">
                <vs-button block @click="addPage()" :disabled="layoutChooseDialogValue == null || pageFlipping || goingToPage">
                    Conferma selezione
                </vs-button>
            </div>
        </template>
    </vs-dialog>

   

    <vs-dialog v-model="elementChooseDialog" prevent-close class="chooseLayout" :class="{'smartMode': smartMode}">
        <template #header>
            <h4 style="margin-bottom: 0;" v-if="!smartMode">
                Scegli un <b>elemento</b>
            </h4>

            <h4 style="margin-bottom: 0;" v-else>
                Compilazione <b>Smart</b> <i class='bx bx-sm bxs-magic-wand' style="color: #b648f6;"></i>
            </h4>

        </template>

        <!-- SORT AND FILTER -->
        <vs-row justify="space-between">
            <vs-button v-if="elementReference != null && elementReference.type == 'IMAGE'" @click="$refs.imageInput.click()">
                <i class='bx bx-upload bx-sm'></i> Carica immagini
            </vs-button>
            <vs-tooltip bottom shadow not-hover v-model="activeFilterTooltip" :loading="postLoading">
                <vs-button icon transparent @click="activeFilterTooltip = true"><i class='bx bx-filter-alt bx-sm'></i></vs-button>
                <template #tooltip>
                    <vs-row style="margin-top: 25px;">
                        <vs-input type="date" label="Dal" block v-model="postFilter.fromDate"></vs-input>
                    </vs-row>
                    <vs-row style="margin-top: 25px;">
                        <vs-input type="date" label="Al" block v-model="postFilter.toDate"></vs-input>
                    </vs-row>

                    <vs-row>
                        <vs-button block transparent v-if="postFilter.sort == 'DESC'" @click="postFilter.sort = 'ASC'"><i class='bx bx-sort-up bx-sm'></i> Decrescente</vs-button>
                        <vs-button block transparent v-if="postFilter.sort == 'ASC'" @click="postFilter.sort = 'DESC'"><i class='bx bx-sort-down bx-sm'></i> Crescente</vs-button>
                    </vs-row>
                    <vs-row>
                        <vs-button block @click="resetFilters"><i class='bx bx-eraser bx-sm'></i> Pulisci filtri</vs-button>
                    </vs-row>
                </template>
            </vs-tooltip>

        </vs-row>
        <vs-row class="posts" style="height: 50vh; overflow-y: auto; overflow-x: hidden; padding: 20px;">
            <template v-if="posts != null">
                <!-- ONLY IMAGE -->
                <template v-if="elementReference != null && elementReference.type == 'IMAGE'">

                    <vs-col w="2" v-for="post in posts" :key="'post-' + post.id">
                        <div @click="selectElement(post)" class="image-box-sel" :class="{'selected': post == elementChooseDialogValue || elementChooseDialogValues.includes(post)}" :style="{'background-image': 'url(' + post.imageUrl + ')'}">
                            <div class="selected" v-if="post == elementChooseDialogValue || elementChooseDialogValues.includes(post)">
                                <i class='bx bx-check bx-sm' v-if="!smartMode"></i>
                                <span style="font-weight: bold;" v-else>
                                    {{ elementChooseDialogValues.indexOf(post) + 1 }}
                                </span>
                            </div>
                        </div>
                    </vs-col>
                </template>

                <!-- ONLY TEXT -->
                <template v-if="elementReference != null && elementReference.type == 'TEXT'">
                    <vs-row v-for="(p,k) in posts" :key="'post-' + k">
                        <vs-col w="12">
                            <Post v-on:click.native="elementChooseDialogValue = p" :title="(p.emozione != null ? ('#' + p.emozione) : p.momento != null ? ('Sta' + p.momento) : '')" :caption="p.caption" :emoji="(p.momento != null ? p.momento : null)" :image="p.imageUrl" :created="p.createdAt" :selected="elementChooseDialogValue == p" />

                        </vs-col>
                    </vs-row>
                </template>
            </template>

        </vs-row>
        <vs-row justify="center" style="margin-top: 20px;">
            <vs-pagination v-model="currentPostsPage" :length="totalPostPages" />
        </vs-row>

        <template #footer>
            <div class="footer-dialog">
                <vs-button block :disabled="elementChooseDialogValue == null" @click="confirmElementSelection" v-if="!smartMode">
                    Conferma selezione
                </vs-button>
                <vs-button size="large" block @click="startSmartCompilation" v-else :disabled="elementChooseDialogValues == null || elementChooseDialogValues.length == 0" :class="{'animate': elementChooseDialogValues != null && elementChooseDialogValues.length > 0}" color="primary">
                    <i class='bx bxs-photo-album bx-sm'></i> Conferma selezione
                </vs-button>
            </div>
        </template>
    </vs-dialog>

   
        <!-- previews-->
        <template v-if="orderDraft && orderDraft.pages">
            <draggable class="pages-preview" :list="orderDraft.pages" group="pages" @start="drag = true" @end="onDragEnd" v-bind="dragOptions" :move="onMoveCallback">
                    <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                        <div class="page-preview" v-for="page in orderDraft.pages" :key="page.id" :style="{'background-image': 'url(' + page.previewFileUrl + ')', maxWidth: '100%', width: 200 + 'px', height: product.height/(product.width/200) + 'px'}" @click="goToPage(page)" :class="{'active': orderDraft.pages && orderDraft.pages[pageNo].id == page.id}" :ref="'pagePreview-' + page.id">
                            <vs-row class="page-preview-tools">
                                <vs-button @click="deletePage(page)" size="small" transparent :disabled="layout && layout.layout.type != 'Page'">
                                    <i class='bx bx-trash bx-sm' style="color: white;"></i>
                                </vs-button>
                                <p>Pagina {{ page.pageNo }}</p>
                            </vs-row>
                        </div>
                    </transition-group>
            </draggable>
            
        </template>
        
            
    <vs-row style="height:calc(80vh - 100px); width: calc(100% - 260px);" justify="center" align="flex-start" class="builder-container">
        

            <vs-row justify="center" align="center" style="gap: 50px; margin-bottom: 50px;" class="builder-actions">

                <vs-button :disabled="product == null || (orderDraft.pages.length - 1) >= product.maxPages" @click="addPageMiddleware" class="add-page">
                    <i class='bx bx-plus bx-sm'></i> <span class="desktop-only">Aggiungi</span> Pagina
                </vs-button>

                <vs-button @click="saveCurrent" class="save-page">
                    <i class="bx bx-save bx-sm"></i> Salva
                </vs-button>
                
                <vs-row align="center" style="gap: 10px; width:unset!important;" class="page-navigation">
                <vs-button circle icon @click="prevPage"><i class='bx bx-chevron-left bx-sm'></i></vs-button>
                <span class="page-num">
                    Pagina {{ pageNo + 1 }} di {{ orderDraft ? orderDraft.pages.length : 0 }}
                </span>
                <vs-button circle icon @click="nextPage"><i class='bx bx-chevron-right bx-sm'></i></vs-button>
                </vs-row>

                <vs-tooltip bottom shadow not-hover v-model="activeCheckoutTooltip" v-if="requiredActions.length == 0">
                    <vs-button icon @click="activeCheckoutTooltip = !activeCheckoutTooltip" :loading="checkoutLoading">
                        <i class='bx bx-cart-alt bx-sm'></i> <span class="desktop-only">Procedi al</span> Checkout
                    </vs-button>
                    <template #tooltip>
                        <div class="content-tooltip">
                            <h4 class="center">
                                Conferma
                            </h4>
                            <p>
                                Una volta eseguito il checkout non sarà più possibile modificare la configurazione del tuo prodotto, sei sicuro di voler proseguire?
                            </p>
                            <footer>
                                <vs-button @click="activeCheckoutTooltip=false" transparent block style="margin: 0; margin-bottom: 5px;">
                                    Annulla
                                </vs-button>
                                <vs-button @click="() => { checkout(); }" block style="margin: 0;">
                                    Prosegui
                                </vs-button>
                            </footer>
                        </div>
                    </template>
                </vs-tooltip>

                <vs-tooltip border-thick color="#b648f6" v-else>
                    <vs-button icon disabled>
                        <i class='bx bx-cart-alt bx-sm'></i> <span class="desktop-only">Procedi al</span> Checkout
                    </vs-button>
                    <template #tooltip>
                        <template v-if="requiredActions[0] != undefined">
                            <template v-if="requiredActions[0] > 0">
                                <!-- PAGINE DA AGGIUNGERE-->
                                <template v-if="Math.abs(requiredActions[0]) > 1">
                                    <!-- PLURALE-->
                                    Sono necessarie ancora {{ Math.abs(requiredActions[0]) }} pagine.
                                </template>
                                <template v-else>
                                    <!-- SINGOLARE-->
                                    È necessaria ancora {{ Math.abs(requiredActions[0]) }} pagina.
                                </template>
                            </template>
                            <template v-else>
                                <!-- PAGINE DA TOGLIERE-->
                                <template v-if="Math.abs(requiredActions[0]) > 1">
                                    <!-- PLURALE-->
                                    È necessario rimuovere {{ Math.abs(requiredActions[0]) }} pagine.
                                </template>
                                <template v-else>
                                    <!-- SINGOLARE-->
                                    È necessario rimuovere {{ Math.abs(requiredActions[0]) }} pagina.
                                </template>
                            </template>
                        </template>

                        <template v-if="requiredActions[1] != undefined">
                            <br />
                            <template v-if="requiredActions[1] > 0">
                                <!-- PAGINE DA AGGIUNGERE-->
                                <template v-if="Math.abs(requiredActions[0]) > 1">
                                    <!-- PLURALE-->
                                    In alternativa aggiungere {{ Math.abs(requiredActions[1]) }} pagine.
                                </template>
                                <template v-else>
                                    <!-- SINGOLARE-->
                                    In alternativa aggiungere {{ Math.abs(requiredActions[1]) }} pagina.
                                </template>
                            </template>
                            <template v-else>
                                <!-- PAGINE DA TOGLIERE-->
                                <template v-if="Math.abs(requiredActions[1]) > 1">
                                    <!-- PLURALE-->
                                    In alternativa rimuovere {{ Math.abs(requiredActions[1]) }} pagine.
                                </template>
                                <template v-else>
                                    <!-- SINGOLARE-->
                                    In alternativa rimuovere {{ Math.abs(requiredActions[1]) }} pagina.
                                </template>
                            </template>
                        </template>

                    </template>
                </vs-tooltip>

                <vs-button @click="showPreview" class="preview">
                    <i class='bx bx-show bx-sm'></i> Anteprima
                </vs-button>

            </vs-row>

            <Builder ref="builder" :builder-data="{product: product, layout: layout, userMode: true, heightSubtract: (window.screen.availHeight*0.33)}" v-if="product && layout" @selectImage="selectImage" @ready="builderReady = true"/>

    </vs-row>
    
</vs-row>
</template>

<script>
import draggable from 'vuedraggable';

import {
    apiCall,
    baseUrl
} from '../client';

import axios from 'axios';
import {
    s3Client
} from '../s3Client';

import Builder from './Admin/Builder.vue';

import Post from '../components/Post';

export default ({
    props: {
        productId: Number
    },
    components: {
        Post,
        Builder,
        draggable
    },

    setup() {

    },

    data() {
        return {
            layouts: [],
            layoutChooseDialog: false,
            layoutChooseDialogValue: null,

            pagesPreviews: [null, ],
            baseUrl,

            elements: [],

            posts: [],

            elementChooseDialog: false,
            elementChooseDialogValue: null,
            elementChooseDialogValues: [],
            elementReference: null,


   

            product: null,
            productVariant: null,

            qrCode: '',

            fonts: [],



            activeFilterTooltip: false,

            postFilter: {
                sort: 'DESC',
                fromDate: null,
                toDate: null,
                showHidden: true
            },

            currentPostsPage: 1,
            totalPostPages: 1,

            uploading: false,

            hasBackCover: false,
            hasEmptyPage: false,

            postLoading: false,

            canExit: false,

            requiredActions: [0],

            activeCheckoutTooltip: false,
            checkoutLoading: false,

            pageFlipping: false,
            goingToPage: false,

            confirmProductUpgradeDialog: false,

            rendering: false,

            // NEW ITEMS: TODO REMOVE OLD ON TOP IF NO LONGER USED

            orderDraft: null,
            pageNo: null,
            layout: null,
            window,
            drag: false,

            newPost: {
                id: null,
                fileId: null
            },
            smartCompletionDialog: false,
            smartMode: false,
            mobileDisclamer: false,

            builderReady: false,

        }
    },

    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
                delay: 250,
                delayOnTouchOnly: true,
            };
        }

    },

    methods: {
        async showPreview() {
            await this.saveCurrent();
            this.$router.push({
                name: 'preview',
                params: {
                    orderId: this.$route.params.orderDraftId,
                    fromBuilder: true
                }
            });
        },

        async saveCurrent(){
            // show loading and save current
            const loading = this.$vs.loading({
                text: 'Salvataggio in corso...',
                type: 'scale',
            });
            await this.savePageDraft(this.orderDraft.pages[this.pageNo])
            loading.close();
        },

        async startSmartCompilation() {

            const images = this.elementChooseDialogValues;
            if (!images.length) return;

            const loading = this.$vs.loading({
                text: 'Caricamento...'
            });

            // Group layouts by number of image slots
            const layoutsBySlots = this.layouts.reduce((acc, layout) => {
                const jsonData = JSON.parse(layout.jsonData);
                const imageSlots = jsonData.filter(e => e.type === 'image').length;
                if (!acc[imageSlots]) acc[imageSlots] = [];
                acc[imageSlots].push(layout);
                return acc;
            }, {});

            console.log('Layouts by slots:', layoutsBySlots);

            // Calculate ratios for selected images
            const imageRatios = await Promise.all(images.map(async (img) => {
                return new Promise((resolve) => {
                    const image = new Image();
                    image.onload = () => {
                        resolve({
                            id: img.id,
                            fileId: img.fileId,
                            ratio: image.naturalWidth / image.naturalHeight
                        });
                    };
                    image.src = img.imageUrl;
                });
            }));

            console.log('Image ratios:', imageRatios);

            let remainingImages = [...imageRatios];
            const totalImages = remainingImages.length;
            while (remainingImages.length > 0) {
                // Find best layout for current batch of images
                const bestLayout = this.findBestLayout(remainingImages, layoutsBySlots);
                if (!bestLayout) break;

                console.log('Best layout:', bestLayout);

                this.builderReady = false;

                // Add new page with selected layout
                const newPage = await this.addPage(bestLayout);
                if (!newPage) break;

                console.log('New page:', newPage);

                console.log('Taking ' + bestLayout.slots + ' images from ' + remainingImages.length + ' remaining images');

                loading.changeText('Inserimento immagini.. ' + (totalImages - remainingImages.length) + '/' + totalImages);

                // Assign images to slots
                const usedImages = remainingImages.splice(0, bestLayout.slots);

                console.log('Used images:', usedImages);

                // wait for this.builderReady to be true
                await new Promise((resolve) => {
                    const interval = setInterval(() => {
                        if (this.builderReady) {
                            clearInterval(interval);
                            resolve();
                        }
                    }, 100);
                });

                await this.assignImagesToSlots(newPage, usedImages);

                console.log('Remaining images:', remainingImages);
            }

            loading.close();

            this.elementChooseDialog = false;
            this.smartCompletionDialog = false;
        },

        findBestLayout(images, layoutsBySlots) {
            // Find layout that can fit the most images but not more than available
            const availableSlots = Object.keys(layoutsBySlots)
                .map(Number)
                .sort((a, b) => b - a)
                .find(slots => slots <= images.length);

            if (!availableSlots) return null;

            // From layouts with that number of slots, find best match for image ratios
            let layouts = layoutsBySlots[availableSlots];
            layouts = layouts.map(layout => {
                const jsonData = JSON.parse(layout.jsonData);
                const imageSlots = jsonData.filter(e => e.type === 'image').map((e) => ({
                    ...e,
                    ratio: e.width / e.height
                }))
                const imageRatios = images.map(i => i.ratio);
                const ratioDiff = imageSlots.reduce((acc, slot, i) => {
                    return acc + Math.abs(slot.ratio - imageRatios[i]);
                }, 0);

                console.log('Layout', layout.id, 'ratioDiff:', ratioDiff);
                return {
                    ...layout,
                    ratioDiff,
                    slots: availableSlots
                };
            });

            layouts.sort((a, b) => a.ratioDiff - b.ratioDiff);
            return layouts[0]; // For now return first layout, could be improved to match ratios better
        },

        async assignImagesToSlots(page, images) {
            const jsonData = JSON.parse(page.jsonData);
            const imageSlots = jsonData.filter(e => e.type === 'image');
            
            for (let i = 0; i < images.length; i++) {
                if (!imageSlots[i]){
                    console.error('No slot for image', images[i]);
                    break;
                }

                console.log('Assigning image', images[i].id, 'to slot', imageSlots[i].id);

                // Simulate file input change for each image
                await this.$refs.builder.fileInputChange({
                    fileName: images[i].fileId,
                    fileScope: 'builderImage',
                    layerId: imageSlots[i].id
                });
            }

            console.log('Images assigned to slots:', images);

            console.log('Page after assignment:', page);
            // Save the page
            await this.savePageDraft(this.orderDraft.pages[page.pageNo - 1]);
        },

       selectElement(post){
        console.log('Selecting ', post);
        if(this.smartMode){
            const existing = this.elementChooseDialogValues.find(e => e.id == post.id);
            if(existing){

                this.elementChooseDialogValues = this.elementChooseDialogValues.filter(e => e.id != post.id);
            } else {
                this.elementChooseDialogValues.push(post);
            }
        } else {
            this.elementChooseDialogValue = post;
        }
       },

        async patchPost(newPost) {
            var result = await apiCall('PATCH', '/Post', { // in this way is no longer a draft
                Id: newPost.id,
                pictureRatio: newPost.pictureRatio,
                UserId: 0,
                Text:  '',
                User: {},
                isHidden: true
            });
            if (result.status != 200) {
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Impossibile aggiornare il post.`
                })
            }
        },

        async fileInputChange() {
            var vm = this;
            const files = this.$refs.imageInput.files;

            let newPosts = [];
            for(var i = 0; i < files.length; i++){
                const file = files[i];
                if (file) {
                    if(file.size > 1024 * 1024 * 10){
                        this.$vs.notification({
                            icon: '<i class="bx bx-error"></i>',
                            color: 'danger',
                            position: 'top-right',
                            title: 'Ops!',
                            text: `La foto non può essere più grande di 10MB.`
                        })
                        return;
                    }
                    const loading = this.$vs.loading({
                        text: 'Caricamento ' + (i + 1) + ' di ' + files.length,
                    })
                    try {
                        let newPost = {
                            id: null,
                            fileId: null
                        };

                        const newPostResult = await apiCall('PUT', '/Post', {
                            Id: '',
                            UserId: 0,
                            Emotion: null,
                            Text: null,
                            User: {},
                            isHidden: true,
                        });

                        if(newPostResult.status == 200){
                            newPost.id = newPostResult.data.id;
                            newPost.fileId = newPostResult.data.fileId;
                        } else {
                            throw new Error('Error creating post');
                        }

                        newPost.pictureRatio = await new Promise((resolve) => {
                            const imageTemp = new Image();
                            imageTemp.src = URL.createObjectURL(file);
                            imageTemp.onload = () => {
                                const r = imageTemp.naturalWidth / imageTemp.naturalHeight;
                                // destroy
                                imageTemp.remove();
                                resolve(r);
                            }

                            imageTemp.onerror = () => {
                                resolve(null);
                            }
                        });


                        const result = await s3Client.uploadFile(file, newPost.fileId);
                        if(result.status == 200){
                            // get signed download url
                            const signedUrl = await s3Client.getSignedDownloadURL({name: newPost.fileId});
                            newPost.imageUrl = signedUrl.url;
                            await this.patchPost(newPost);
                            this.posts.unshift(newPost);
                        } else {
                            throw new Error('Error uploading file');
                        }

                        newPosts.push(newPost);
                    } catch (error) {
                        console.error(error);
                        this.$vs.notification({
                            icon: '<i class="bx bx-error"></i>',
                            color: 'danger',
                            position: 'top-right',
                            title: 'Ops!',
                            text: `Impossibile caricare la foto.`
                        })
                    }

                    loading.close();
                }
            }

            // if i was in smart mode, select them
            if(this.smartMode){
                this.elementChooseDialogValues = this.elementChooseDialogValues.concat(this.posts.filter(p => newPosts.map(e => e.id).includes(p.id)));
            }

            // reset
            this.$refs.imageInput.value = null;
            
        },

        selectImage(id){
            this.elementReference = {type: 'IMAGE', id}; 
            this.elementChooseDialog = true
        },

        confirmElementSelection(){
            // if image
            if(this.elementReference.type == 'IMAGE'){
                this.$refs.builder.fileInputChange({
                    fileName: this.elementChooseDialogValue.fileId,
                    fileScope: 'builderImage',
                    postProcess: 'cropper',
                    layerId: this.elementReference.id
                });
            }

            this.elementChooseDialog = false;
        },

        resetFilters() {
            this.postFilter = {
                sort: 'DESC',
                fromDate: null,
                toDate: null,
            }
        },

        async onDragEnd(evt){
            this.drag = false;


            // patch page with new pageNo
            const newIndex = evt.newIndex;
            let page = this.orderDraft.pages[newIndex];
            if(!page){
                return;
            }

            

            const oldPageNo = page.pageNo;

            page.pageNo = newIndex + 1;
            //console.log(oldPageNo + ' MOVED TO '+ page.pageNo);

            await this.savePageOrderOnly(page);

            // call getOrderDraft
            const orderDraftResponse = await this.getOrderDraft();
            if(orderDraftResponse.status != 200){
                this.$vs.notification({
                    title: 'Errore',
                    text: 'Impossibile aggiornare l\'ordine',
                    color: 'danger',
                    position: 'top-right'
                });
                return;
            }

            // if the moved page was the selected one, update current selected page no
            if(this.pageNo == oldPageNo - 1){
                this.pageNo = page.pageNo - 1;
            }

            // override pageNo only
            this.orderDraft.pages.forEach((e, i) => {
                //console.log('New page no for page', e.id, 'is', orderDraftResponse.data.pages.find(p => p.id == e.id).pageNo);
                e.pageNo = orderDraftResponse.data.pages.find(p => p.id == e.id).pageNo;
            });

            this.orderDraft.__ob__.dep.notify();
        },

        onMoveCallback(evt) {
            // understand what are proibithed indexes: pages with Types different from Page ARE NOT ALLOWED
            let proibithedIndexes = this.orderDraft.pages.map((e, i) => {
                if (e.type != 'Page') {
                    return i;
                }
            }).filter(e => e != undefined);

            //console.log(proibithedIndexes)

            if (proibithedIndexes.includes(evt.draggedContext.futureIndex)) {
                return false;
            }

            if(proibithedIndexes.includes(evt.draggedContext.index)){
                return false;
            }

            return true;
        },

        async getOrderDraft(){
            var orderDraftResponse = await apiCall('GET', '/Order/Draft/' + this.$route.params.orderDraftId);
            return orderDraftResponse;
        },

        async loadDraft(pageNo = null){
            // obtain the orderDraft
            var orderDraftResponse = await this.getOrderDraft();
            if (orderDraftResponse.status == 200) {
                var draft = orderDraftResponse.data;

                await Promise.all(draft.pages.map(async (p) => {
                    var pictureId = p.id;
                    if (pictureId != null) {
                        const signedUrl = await s3Client.getSignedDownloadURL({name: pictureId});

                        const exists = await s3Client.fileExists(signedUrl);

                        if(exists){
                            p.previewFileUrl = signedUrl.url;
                        } else {
                            // try to load using || p.previewPictureId
                            const defaultSignedUrl = await s3Client.getSignedDownloadURL({name: p.previewPictureId});

                            const existsDefault = await s3Client.fileExists(defaultSignedUrl);

                            if(existsDefault){
                                p.previewFileUrl = defaultSignedUrl.url;
                            }
                        }
                    }
                }));


                this.orderDraft = draft;

                this.$nextTick(() => {
                    if(pageNo != null){
                        //console.log('LOADED PAGES', this.orderDraft.pages);
                        //console.log('GOING TO PAGE', pageNo);
                        this.goToPage(pageNo, true);
                    }
                });

            } else {
                this.$router.push({
                    name: 'feed'
                });
                return;
            }
        },

        async savePageOrderOnly(page){
            
            // 2. Patch
            const result = await apiCall('PATCH', '/Order/' + this.orderDraft.id + '/Pages/' + page.id, {
                pageNo: page.pageNo
            });

            if(!result || result.status != 200){
                this.$vs.notification({
                    title: 'Errore',
                    text: 'Impossibile salvare la pagina',
                    color: 'danger',
                    position: 'top-right'
                });
            }
        },

        async savePageDraft(page){
            await new Promise((resolve) => {
                // 1. generate preview
                this.$refs.builder.savePreviewPicture(page.id).then(async () => {
                    // get page preview
                    const signedUrl = await s3Client.getSignedDownloadURL({name: page.id});

                    const exists = await s3Client.fileExists(signedUrl);
                    if(exists){
                        // fetch it using axios as blob, then generate url and set it: this prevents the browser from showing no image before loading
                        const imageResult = await axios.get(signedUrl.url, {
                            responseType: 'blob'
                        });

                        const blob = new Blob([imageResult.data], {type: imageResult.headers['content-type']});

                        page.previewFileUrl = URL.createObjectURL(blob);
                        page.__ob__.dep.notify();
                    }

                    // 2. Patch
                    const result = await apiCall('PATCH', '/Order/' + this.orderDraft.id + '/Pages/' + page.id, {
                        jsonData: this.$refs.builder.getSerializedData(),
                    });

                    if(!result || result.status != 200){
                        this.$vs.notification({
                            title: 'Errore',
                            text: 'Impossibile salvare la pagina',
                            color: 'danger',
                            position: 'top-right'
                        });
                    }

                    resolve();
                });
            });
            
            
        },

        async nextPage() {
            if (this.pageNo + 1 < this.orderDraft.pages.length) {
                this.goToPage(this.pageNo + 1);
            }
        },

        async prevPage() {
            if (this.pageNo > 0) {
                this.goToPage(this.pageNo - 1);
            }
        },

        async addPage(selectedLayout = null){
            if(selectedLayout == null){
                selectedLayout = this.layouts.find(e => e.id == this.layoutChooseDialogValue);
            }
            // call PUT /Order/{id}/Page
            const result = await apiCall('PUT', '/Order/' + this.orderDraft.id + '/Page', {
                layoutId: selectedLayout.id,
                pageNo: this.pageNo + 1,
            });
            

            if(result.status == 200){
                // save the page if exists
                if(this.layout != null){
                    await this.savePageDraft(this.orderDraft.pages[this.pageNo]);
                }

                this.orderDraft.pages.push({
                    ...result.data,
                    previewFileUrl: selectedLayout.previewFileUrl,
                });
                this.layoutChooseDialog = false;

                // reload order pages draft
                await this.loadDraft(result.data.pageNo - 1); // TAKES INDEX IF NUMBER, OTHERWISE OBJECT BUT IT LOOKS UP BY ID

                return result.data;
            } else {
                this.$vs.notification({
                    title: 'Errore',
                    text: 'Impossibile aggiungere la pagina',
                    color: 'danger',
                    position: 'top-right'
                });
            }

        },

        async goToPage(page, skipSave = false){


            var currentPage = this.orderDraft.pages[this.pageNo];


            if(typeof page == 'number'){
                page = this.orderDraft.pages[page];
            } else if(typeof page == 'object'){
                page = this.orderDraft.pages.find(e => e.id == page.id);
            }


            // check if page.id is equal to currentPage.id
            if(currentPage != null && page.id == currentPage.id){
                console.log('Already on page (' + page.id + ', ' + currentPage.id + ')');
                return;
            }


            //console.log(this.$refs['pagePreview-' + page.id])
            const loading = this.$vs.loading({
                target: this.$refs['pagePreview-' + page.id] ? this.$refs['pagePreview-' + page.id][0] : null,
                color: 'dark',
                text: 'Caricamento...',
                type: 'scale',

            });

            // wait 500ms
            await new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                }, 150);
            });

            if(this.layout != null && this.orderDraft.pages[this.pageNo] != null && !skipSave){
                await this.savePageDraft(this.orderDraft.pages[this.pageNo]);
            }

            this.layout = null; // destroy builder

            this.$nextTick(() => {
                this.pageNo = this.orderDraft.pages.indexOf(page);

                //console.log('Dipatch', this.pageNo);

                loading.close();
            });

            
        },

        addPageMiddleware(){
            // check if we are going into next product variant
            if(this.orderDraft.pages.length == this.productVariant.pages){
                this.confirmProductUpgradeDialog = true;
                return;
            }

            this.layoutChooseDialog = true;
        },

        async deletePage(page) {
            // call delete method, on success remove it also from orderDraft.pages
            const result = await apiCall('DELETE', '/Order/' + this.orderDraft.id + '/Pages/' + page.id);
            if(result.status == 200){
                this.orderDraft.pages = this.orderDraft.pages.filter(e => e.id != page.id);
                var p = this.pageNo - 1;
                if(p < 0){
                    p = 0;
                }
                // force builder to destroy
                this.layout = null;

                // reload orderDraft pages
                await this.loadDraft();

                this.$nextTick(() => {
                    this.goToPage(p, true);
                });
            } else {
                this.$vs.notification({
                    title: 'Errore',
                    text: 'Impossibile eliminare la pagina',
                    color: 'danger',
                    position: 'top-right'
                });
            }
        },


        async checkout() {
            this.canExit = true;
            this.activeCheckoutTooltip = false;
            this.checkoutLoading = true;

            // first save draft
            await this.savePageDraft(this.orderDraft.pages[this.pageNo]);

            setTimeout(() => {
                var vm = this;
                this.$router.push({
                    name: 'checkout',
                    params: {
                        orderDraftId: vm.orderDraft.id,
                        product: vm.product,
                        orderDraft: vm.orderDraft
                    }
                });
            }, 1000); // in this way the tooltip has time to close

        },

        async getPosts(type) {
            this.postLoading = true;
            var p = JSON.parse(JSON.stringify(this.postFilter)); // deep copy
            p.page = this.currentPostsPage - 1; // for us it starts from 0
            p.type = type;
            var result = await apiCall('POST', '/Post', p);
            this.postLoading = false;
            if (result.status == 200) {
                var newPosts = await Promise.all(result.data.posts.map(async (e) => {
                    var imageUrl = null;
                    if (e.fileId != null) {
                        const signedUrl = await s3Client.getSignedDownloadURL({name: e.fileId});

                        const exists = await s3Client.fileExists(signedUrl);
                        if(exists){
                            imageUrl = signedUrl.url;
                        }
                    }
                    return {
                        id: e.id,
                        emozione: e.emotion,
                        momento: null,
                        caption: e.text,
                        imageUrl,
                        createdAt: new Date(e.createdAt),
                        fileId: e.fileId
                    }
                }));
                this.posts = newPosts;
                this.totalPostPages = Math.ceil(result.data.total / 24);
            }
        },

        
        evaluateProductVariants(pages) {
            //console.log('EVALUATE', pages);
            if (this.product.productVariants == undefined) {
                return;
            }

            var pv = this.product.productVariants.find(e => e.pages >= pages);
            //console.log('PV', pv);
            this.productVariant = pv != undefined ? pv : this.productVariant;

            this.requiredActions = [];

            var perfectMatch = this.product.productVariants.find(e => e.pages == pages);
            if (perfectMatch != null) {
                return;
            }

            var greater = this.product.productVariants.find(e => e.pages > pages);
            if (greater != null) {
                var gDelta = greater.pages - pages;
                this.requiredActions.push(gDelta);
            }

            var lower = this.product.productVariants.find(e => e.pages < pages);
            if (lower != null) {
                var gLower = lower.pages - pages;
                this.requiredActions.push(gLower);
            }
        }

    },

    watch: {
        requiredActions(n) {
            //console.log('REQUIRED', n);
        },




        'orderDraft.pages'(n) {
            this.evaluateProductVariants(n.length) // -1 because the first one is null always
        },
        
        elementChooseDialog(n) {
            if (n) {
                this.elementChooseDialogValue = null;
                this.elementChooseDialogValues = [];
                this.getPosts(this.elementReference.type);
            }

            if(!n){
                this.smartMode = false;
            }
        },

        

        postFilter: {
            deep: true,
            handler: function () {
                if (this.currentPostsPage > 0) { // se era a una pagina maggiore di zero la imposto a 0, e questo in automatico ritriggera il caricamento del feed
                    this.currentPostsPage = 0;
                } else { // se invece era già a 0, ricarico il feed manualmente
                    this.getPosts(this.elementReference.type);
                }

            }
        },

        currentPostsPage() {
            this.getPosts(this.elementReference.type);
        },

        async pageNo(n){
            const result = await apiCall('GET', '/Order/Draft/' + this.orderDraft.id + '/Page/' + this.orderDraft.pages[n].id);
            if(result.status == 200){
                this.layout = result.data;
            }
        }
    },

    beforeRouteLeave(to, from, next) {
        if (this.canExit || this.$parent.user == null) {
            next();
            return;
        }

        const loading = this.$vs.loading({
            text: 'Salvataggio in corso...',
            type: 'scale'
        });

        this.savePageDraft(this.orderDraft.pages[this.pageNo]).then(() => {
            loading.close();
            next();
        });
    },

    async mounted() {
        const loading = this.$vs.loading({
            text: 'Preparazione del builder...',
            type: 'scale'
        });
        this.mobileDisclamer = window.innerWidth < 768;

        if (this.productId == null || this.productId < 1) {
            this.$router.push({
                name: 'feed'
            }); // tentativo di accesso diretto
            loading.close();
            return;
        }
        let newProject = false;
        if(this.$route.params.orderDraftId == null){
            newProject = true;
            loading.changeText('Creazione del progetto...');

            // PUT OrderDraft
            var res = await apiCall('PUT', '/Order/Draft', {
                productId: this.productId
            });
            //console.log(res);
            if (res.status == 200) {
                // replace in current route
                this.$router.replace({
                    name: 'store',
                    params: {
                        productId: this.productId,
                        orderDraftId: res.data.id
                    }
                });
            }
        }

        await this.loadDraft();

        if(this.orderDraft.pages.length){
            this.$nextTick(() => {
                this.goToPage(0, true);
            });
        }


        result = await apiCall('GET', '/Font');
        if (result.status == 200) {
            this.fonts = result.data;
        }

        result = await apiCall('GET', '/Product');
        if (result.status == 200) {
            this.product = result.data.find(e => e.id == this.productId);

            // load preview picture
            if (this.product.fileId != null) {
                const signedUrl = await s3Client.getSignedDownloadURL({name: this.product.fileId});

                const exists = await s3Client.fileExists(signedUrl);
                if(exists){
                    this.product.previewFileUrl = signedUrl.url;
                }
            }

            //console.log(this.product);
            this.evaluateProductVariants(this.orderDraft.pages.length )

            // find the variant with higher number of pages and set product.maxPages to that value
            this.product.maxPages = Math.max.apply(Math, this.product.productVariants.map(function (o) {
                return o.pages;
            }));

            // find the variant with lower number of pages and set it as current productVariant
            this.productVariant = this.product.productVariants.find(e => e.pages == Math.min.apply(Math, this.product.productVariants.map(function (o) {
                return o.pages;
            })));
        }


        var result = await apiCall('GET', '/Product/Layout');
        //console.log(result);
        if (result.status == 200) {
            let layouts = result.data.filter(e => e.product && e.product.id == this.productId);

            await Promise.all(layouts.map(async (l) => {
                if (l.previewPictureId != null) {
                    const signedUrl = await s3Client.getSignedDownloadURL({name: l.previewPictureId});

                    const exists = await s3Client.fileExists(signedUrl);
                    if(exists){
                        l.previewFileUrl = signedUrl.url;
                    }
                }
            }));

            this.layouts = layouts;
        }


        setTimeout(() => {
            loading.close();
        }, 500);

        if(newProject){
            // propose smart mode
            this.smartCompletionDialog = true;
        }

    },
})
</script>

<style>
.smartMode .vs-dialog {
    border: 2px solid rgba(var(--vs-primary), 0.5);
    animation: smartModeBorder 1.5s ease-in-out infinite alternate;
}

@keyframes smartModeBorder {
    0% {
        box-shadow: 0 0 5px 0 rgba(var(--vs-primary), 0.5);
    }
    100% {
        box-shadow: 0 0 15px 5px rgba(var(--vs-primary), 0.5);
    }
}
.image-box-sel {
    padding-bottom: 100%;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    opacity: 0.6;
}

.image-box-sel:hover {
    opacity: 0.8;
    cursor: pointer;
}

.image-box-sel.selected {
    opacity: 1;
}

.image-box-sel::before,
.image-box-sel::after {
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
}

.image-box-sel::before {
    backdrop-filter: blur(10px) brightness(0.8);
}

.image-box-sel::after {
    background-image: inherit;
    background-size: contain;
    background-position: inherit;
    background-repeat: inherit;
}

.chooseLayout .vs-dialog {
    width: 60%;
}

.preview-container {
    width: 500px;
    height: 250px;
    position: relative;
    overflow: hidden;
    text-align: center;
}

.preview-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    filter: blur(10px);
    transform: scale(1.1);
}

.preview-image {
    max-width: 100%;
    max-height: 100%;
}

.store .vs-card__img {
    max-height: 150px;
}

.store .vs-card__img:first-child:not(img) {
    height: 150px;
}


.disabled-section {
    pointer-events: none!important;
    opacity: 0.6;
}

/* .page with child .store 's header padding 0 */

.page:has(.store) header {
    padding-bottom: 10px;
}

.page:has(.store){
    overflow: unset!important;
    height: unset!important;
}

.page-preview {
    background-size: cover;
    border-radius: 5px;
    overflow: hidden;
    filter: brightness(0.7);
    transition: filter 0.3s;
}
/* on hover make it darker using filter */
.page-preview:hover {
    filter: brightness(1);
    cursor: pointer;
}

.page-preview.active {
    filter: brightness(1);
}


.pages-preview {
    height: calc(80vh);
    width: 260px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px;
    box-sizing: border-box;
    gap: 20px;
    flex-direction: column!important;
    align-items: flex-start!important;
    justify-content: flex-start!important;
    display: flex;
}

.pages-preview span {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-right:10px;
    width: 100%;
    box-sizing: border-box;
}

.page-preview-tools {
    position: absolute;
    bottom: 0;
    left: 0;
    padding-bottom: 45px;
    right: 0;
    /* fradient background */
    background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.4) 40%, rgba(0,0,0,0) 100%);
    color: white;

}
.page-preview-tools p{
    margin: 0;
    /* soft shadow */

    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    bottom: 10px;
}

.page-preview-tools .vs-button {
    position: absolute;
    bottom: 0;
    right: 0;
}

.page-preview:not(.active) .page-preview-tools .vs-button {
    display: none;
}

.page-preview:has(.vs-loading) {
    cursor: not-allowed;
    pointer-events: none!important;
}

/* from mobile set store flex-direction to column, and set pages-preview to 100% width and flex-direction to row, overflow-y to hidden and overflow-x to auto, width of builder-container to 100% */
@media (max-width: 768px) {
    .store {
        flex-direction: column-reverse!important;
        height: unset!important;
    }

    .pages-preview {
        width: 100%!important;
        flex-direction: column!important;
        overflow-y: hidden;
        overflow-x: auto;
        height: 20vh!important;
    }

    /* scrollbar of pages-preview should be thin and minimal */
    .pages-preview {
        scrollbar-width: thin;
        scrollbar-color: #bbb transparent;
    }
    .pages-preview::-webkit-scrollbar {
        height: 10px;
    }
    .pages-preview::-webkit-scrollbar-track {
        background: transparent;
    }
    .pages-preview::-webkit-scrollbar-thumb {
        background-color: #919191;
        border-radius: 10px;
    }

    .pages-preview span {
        display: flex;
        flex-direction: row;
        padding-right: 0!important;
        width:unset!important;
    }

    .page-preview {
        max-width:unset!important;
    }

    .builder-container {
        width: 100%!important;
        height: unset!important;
    }

    .builder-actions {
        gap: 10px!important;
        margin-bottom: 20px!important;
    }

    /* change order of save-page and add-page, page-navigation and checkout */
    /* order must be: save-page,add-page, checkout, page-navigation */
    .builder-actions .add-page {
        order: 2;
    }
    .builder-actions .save-page {
        order: 1;
    }
    .builder-actions .page-navigation {
        order: 4;
    }

    /* footer disappears */
    .page:has(.store) .footer {
        display: none;
    }
}

.store span.desktop-only {
    margin-right: 3px;
}

.posts {
    display: flex;
  flex-flow: row wrap;
    gap:0;
    height: auto!important;
}

.image-box-sel .selected {
    position: absolute;
    top: 2px;
    right: 2px;
    background-color: rgba(var(--vs-primary), 0.9);
    z-index: 1;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 100%;

}
.image-box-sel {
    border: 2px solid transparent;
}
.image-box-sel:has(.selected){
    border: 2px solid rgba(var(--vs-primary), 0.9);
}
</style>
