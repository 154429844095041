<template>
    <vs-card class="post" :class="{'text-only': image == null, 'selected': selected}" type="3">
        <template #title>
            <vs-row justify="flex-end" align="center" style="gap: 10px;">
                <i class="bx bx-sm bx-time-five"></i>
                <p>{{ getTimeAgo() }}</p>
            </vs-row>
            <vs-row justify="flex-start" align="center">
                <h3>{{title}} </h3><img v-if="emoji != null" :src="getEmojiUrl(emoji)" style="margin-left: 5px; width: 32px; height: auto;" />
            </vs-row>
            
        </template>
        <template #img v-if="image != null">
            <div class="image-box" :style="{'background-image': 'url(' + image + ')'}"></div>
        </template>
        <template #text>
            <p>
                {{caption}}
            </p>
        </template>
        <!--<template #interactions>
            <vs-button dark>
                <p><b>Compleanno</b></p><img src="../assets/emoji/birthday_cake.png" style="margin-left: 5px; width: 32px; height: auto;" />

            </vs-button>

        </template>-->
    </vs-card>
</template>
<script>
import TimeAgo from 'javascript-time-ago'
import it from 'javascript-time-ago/locale/it'

export default {
  name: 'Post',
  props: {
    title: String,
    caption: String,
    emoji: String,
    image: String,
    created: Date,
    selected: {
        type: Boolean,
        default: false
    }
  },

  methods: {
    getEmojiUrl(e) {
        var images = require.context('../assets/emoji/', false, /\.png$/)
        return images('./' + e + ".png")
    },

    getTimeAgo(){
        TimeAgo.addLocale(it);
        const timeAgo = new TimeAgo('it');
        var d = new Date(this.created.getTime() - this.created.getTimezoneOffset()*60*1000); // to locale timezone
        return timeAgo.format(d);
    }
  }
}
</script>
<style scoped>
.vs-card-content.type-1 {
    margin-bottom: 20px;
}

</style>
<style >
.post .vs-card {
    max-width: unset!important;
    margin-bottom: 20px;
    
}

.post.selected .vs-card {
    border: 3px solid #ffc832;
}

.post .vs-card__img {
    width: 30%!important;
}
.post .vs-card__text {
    width: 70%!important;
}
.post.text-only .vs-card__text {
    width: 100%!important;
}
.post .vs-card__title h3 {
    font-size: 1.5rem;
}
.post .vs-card__text {
    max-height: 250px;
}

.post .vs-card__text p {
    white-space: break-spaces;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 120px;
}
</style>